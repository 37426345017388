import { FormattedMessage, useIntl } from "react-intl";
import * as Sentry from "@sentry/nextjs";
import { FullPageWarning, usePaymentLabsRouter } from "@pl/app-common";

export default function Page() {
  Sentry.setTag("page", "404");
  const { formatMessage } = useIntl();
  const router = usePaymentLabsRouter();

  return (
    <FullPageWarning
      warningContext="404"
      titleText={<FormattedMessage id="error.404.title.not-found" />}
      bodyText={<FormattedMessage id="error.404.body.resource-not-found" />}
      ctaText={formatMessage({ id: "global.action.go-to-dashboard" })}
      cta={() => router.goToIndex()}
    />
  );
}
